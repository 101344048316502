import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import { InlineWidget } from "react-calendly";
// import { FaParking } from "react-icons/fa";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>Formació Grupal Role Play Terrassa 2024 (Grup #3 - 30 juny 9 a 14 h)</Title>
                <Text>
                    Èxit absolut en l'anterior convocatòria de Terrassa amb aquest curs que s'ha
                    convertit en obligatori.
                    <br />
                    <br />
                    Us portem una nova edició del millor curs presencial per a preparar
                    l'entrevista, els casos pràctics i el role play en grup.
                    <br />
                    <br />
                    Impartit per un agent de Terrassai un psicòleg.
                    <br />
                    <br />
                    Grup de 14 persones en un escenari.
                    <br />
                    <br />
                    - 5 hores intensives
                    <br />
                    <br />
                    Què treballarem?
                    <br />
                    <br />
                    -Bateria de preguntes oficials
                    <br />
                    <br />
                    - Casos pràctics exposats en públic.
                    <br />
                    <br />
                    - Situacions d'estrès
                    <br />
                    <br />
                    - Exposició en públic
                    <br />
                    <br />
                    ✅ Valoració de competències, comunicació no verbal i iniciativa.
                    <br />
                    <br />
                    És una experiència 100x100 immersiva, no apta per a persones introvertides.
                    <br />
                    <br />
                    El curs es realitza al c/Martí Codolar núm. 18, Hospitalet de Llobregat.
                </Text>

                {/*<div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                        <iframe
                            title={item.title}
                            width="100%"
                            height="600px"
                            src="https://www.youtube.com/embed/S6UpxRZZXXg"
                            frameBorder={"0"}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen={true}
                        />
                    </div>*/}
            </div>
        </ProductTemplate>
    );
};
export default Product;
